<template>
	<app>
		<flow-node :node="nodeList" :current="currentNode" :reject="rejectNode"></flow-node>
		<c-detail>
			<c-detail-item label="项目名称" full>
				{{detailInfo.project_name}}
			</c-detail-item>
			<c-detail-item label="项目编号">
				{{detailInfo.project_code}}
			</c-detail-item>
			<c-detail-item label="监理单位">
				{{detailInfo.jldw_name}}
			</c-detail-item>
			<c-detail-item label="业主单位">
				{{detailInfo.yzdw_name}}
			</c-detail-item>
			<c-detail-item label="施工建设地址">
				{{detailInfo.xm_address}}
			</c-detail-item>
			<c-detail-item label="企业名称">
				{{detailInfo.zbqy_name}}
			</c-detail-item>
			<c-detail-item label="企业联系人">
				{{detailInfo.zbqy_fr_name}}<span v-if="detailInfo.zbqy_phone">（{{detailInfo.zbqy_phone}}）</span>
			</c-detail-item>
			<c-detail-item label="营业执照">
				<c-media :src="detailInfo.yyzz_path"></c-media>
			</c-detail-item>
			<c-detail-item label="法人身份证头像面">
				<c-media :src="detailInfo.frsfztx_path"></c-media>
			</c-detail-item>
			<c-detail-item label="资质证书">
				<c-media :src="detailInfo.zzzs_path"></c-media>
			</c-detail-item>
			<c-detail-item label="法人身份证国徽面">
				<c-media :src="detailInfo.frsfzgh_path"></c-media>
			</c-detail-item>
			<c-detail-item label="中标通知书">
				<c-file :src="detailInfo.zb_path"></c-file>
			</c-detail-item>
			<c-detail-item label="施工方案">
				<c-file :src="detailInfo.sgfa_path"></c-file>
			</c-detail-item>
			<c-detail-item label="其他文件">
				<c-file :src="detailInfo.other_path"></c-file>
			</c-detail-item>
			<c-detail-item label="备注说明">
				{{detailInfo.remark}}
			</c-detail-item>
		</c-detail>
		
		<c-detail title="项目关联保函">
			<div slot="custom">
				<detail-card-m v-for="(item, i) in insuranceList" :key="i">
					<div class="flex-between-center">
						<div class="name">{{item.bx_type_name}}</div>
						<div class="state" :style="{'color': insuranceState[item.state].color}">{{insuranceState[item.state].name}}</div>
					</div>
					<div class="info">被保险人：{{item.bbxr_name}}</div>
					<div v-if="item.bd_money" class="info">保险额度：{{item.bd_money}}元</div>
					<div v-if="item.bx_bd_state_date" class="info">保单有效期：{{item.bx_bd_state_date}}至{{item.bx_bd_end_date}}</div>
					<div v-if="item.bx_bd_state_date" class="info">
						距离保单过期：<span v-if="item.exp_date >= 0" style="color: #f00;">还有{{item.exp_date}}天</span>
						<span v-else style="color: #f00;">已过期</span>
					</div>
				</detail-card-m>
			</div>
		</c-detail>
		
		<c-detail title="审批流程">
			<timeline :data="timeline"></timeline>
		</c-detail>
			
		<button-list-m v-if="approveButton" slot="foot">
			<c-button color="green" size="m" @click="$refs.approve.resolve()">审批通过</c-button>
			<c-button color="red" size="m" @click="$refs.approve.reject()">驳回</c-button>
		</button-list-m>
		
		<approve ref="approve" @resolve="approveHandle"></approve>
	</app>
</template>

<script>
	import flowNode from '@/components/module/flow_node.vue'
	import timeline from '@/components/module/timeline.vue'
	import approve from '@/components/module/approve.vue'
	import {mapGetters} from 'vuex'
	
	export default {
		components: {
			flowNode,
			timeline,
			approve
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				nodeList: [{
					name: '提交申请'
				}, {
					name: '(监理方)审批'
				}, {
					name: '(业主方)审批'
				}, {
					name: '办理完结'
				}],
				detailInfo: {},
				insuranceList: [],
				timeline: []
			}
		},
		
		computed: {
			...mapGetters(['proceedState', 'insuranceState']),
			
			currentNode() {
				return this.detailInfo.state <= 3 ? this.detailInfo.state + 1 : 0;
			},
			
			rejectNode() {
				return this.detailInfo.state == 4 ? (this.detailInfo.reject_user_type == 8 ? 2 : 3) : 0;
			},
			
			approveButton() {
				return this.detailInfo.state == 2;
			}
		},
		
		mounted() {
			this.updateDetail();
		},
		
		methods: {
			updateDetail() {
				this.request({
					url: '/project/kgl/detail',
					data: {
						kgl_id: this.id
					},
					success: data => {
						this.detailInfo = data.kglInfo;
						this.insuranceList = data.bx_list;
						this.timeline = Array.from(data.logList, item => {
							return {
								date: item.create_date,
								content: `[${item.create_user_name}] ${item.remark}`
							}
						});
					}
				});
			},
			
			approveHandle(state, remark, stop, next) {
				stop();
				
				this.request({
					url: '/project/kgl/updateStateYz',
					data: {
						kgl_id: this.id,
						state: state ? 3 : 4,
						yz_remark: remark
					},
					loading: true,
					success: data => {
						next();
						this.$message({
							text: '审批成功',
							resolve: () => {
								this.updateDetail();
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>