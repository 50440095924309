<template>
	<mobile v-if="mobile" :id="id"></mobile>
	<pc v-else :id="id"></pc>
</template>

<script>
	import pc from './proceed_detail_pc.vue'
	import mobile from './proceed_detail_m.vue'
	import {mapState} from 'vuex'
	
	export default {
		name: 'proceed_detail',
		
		components: {
			pc,
			mobile
		},
		
		props: {
			id: String
		},
		
		computed: {
			...mapState(['mobile'])
		}
	};
</script>

<style lang="stylus" scoped>
</style>